import { createContext, useState } from "react";

export const ProjectContext = createContext(null);

const ProjectContextProvider = ({ children }) => {
  const [projectId, setProjectId] = useState("");
  const [projectIdReport, setProjectIdReport] = useState("");
  const [userId, setUserId] = useState("");
  const [projectName, setProjectName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [selectedProject, setSelectedProject] = useState("");

  return (
    <ProjectContext.Provider
      value={{
        projectId,
        setProjectId,
        userId,
        setUserId,
        projectName,
        setProjectName,
        apiKey,
        setApiKey,
        websiteUrl,
        setWebsiteUrl,
        selectedProject,
        setSelectedProject,
        projectIdReport,
        setProjectIdReport,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
