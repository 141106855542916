import { Button } from "@mantine/core";
import React, { useEffect } from "react";
import { useForm } from "@mantine/form";
import { toast } from "react-toastify";
import instance from "../axios/global";
import useProjectContext from "../hooks/useProjectContext";
import {
  Select,
  Modal,
  Input,
  TextInput,
  Checkbox,
  Group,
  Box,
  Textarea,
} from "@mantine/core";
import { useParams } from "react-router-dom";

const Settings = () => {
  const { projectId } = useProjectContext();
  const { id } = useParams();
  console.log("🚀 ~ file: Settings.js:22 ~ Settings ~ id:", id);
  const form = useForm({
    initialValues: {
      name: "",
      siteUrl: "",
      apiKey: "",
    },
    validate: {
      name: (value) => {
        console.log("🚀 ~ file: Navbar.js:38 ~ Navbar ~ value:", value);
        return value.length === 0 ? "Veuillez remplir ce champs" : null;
      },
      /*       siteUrl: (value) => (value !== null ? null : "Breed can't be empty"),
      apiKey: (value) => (value !== null ? null : "Name can't be empty"), */
    },
  });

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const res = await instance.get(`/client/${id}`);
        form.setValues({
          name: res.data.name,
          siteUrl: res.data.site_url,
          apiKey: res.data.api_key,
        });
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchClient();
  }, []);

  const handleSubmitClient = async (data) => {
    console.log("🚀 ~ file: Navbar.js:83 ~ handleSubmitClient ~ data:", data);

    if (form.isValid) {
      //Submit form
      try {
        const res = await instance.put(`/client/${projectId}`, {
          name: data.name,
          site_url: data.siteUrl,
          api_key: data.apiKey,
        });
        toast.success("Votre client a bien été modifié.", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-message",
        });
        form.reset();
        console.log("🚀 ~ file: Navbar.js:93 ~ handleSubmitClient ~ res:", res);
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_LEFT,
        });
        console.log("Error:", error);
      }
    }
    //Or the other way
  };

  return (
    <div className="settings">
      {/*    <Tables clientList={clientList} /> */}
      <div>
        <h1 style={{ color: "#E2725B" }}>{"Paramètres"}</h1>
      </div>

      <form onSubmit={form.onSubmit((values) => handleSubmitClient(values))}>
        <div className="form-input">
          <TextInput
            style={{ marginTop: "2rem" }}
            label="Nom"
            placeholder="Audi...."
            {...form.getInputProps("name")}
          />
          <TextInput
            style={{ marginTop: "2rem" }}
            label="Site url"
            placeholder="Site url...."
            {...form.getInputProps("siteUrl")}
          />
        </div>
        {/*         <Textarea
          disabled
          style={{ marginTop: "2rem" }}
          label="Clé api"
          placeholder="Clé api...."
          {...form.getInputProps("apiKey")}
        /> */}
        <Group justify="flex-end" mt="md">
          <Button color="#E2725B" style={{ marginTop: "1rem" }} type="submit">
            Submit
          </Button>
        </Group>
      </form>
    </div>
  );
};

export default Settings;
