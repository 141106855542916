import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";

// Register the ChartDataLabels plugin

const SeaFocusChart = ({ /* aggregatedData */ dateRange, reportData }) => {
  Chart.register(ChartDataLabels);
  // Initialisation de l'objet agrégé

  // Supposons que 'reportData' contienne vos données de campagne
  const campaign = reportData;
  const aggregatedData = {};

  // Étape 1: Filtrer les cellules qui ont position === 1
  const filteredCells = campaign?.cell?.filter((cell) => cell.position === 1);

  // Étape 2: Parcourir les cellules filtrées pour calculer les statistiques
  filteredCells?.forEach((cell) => {
    const date = new Date(cell.date).toLocaleDateString("fr-FR");

    // Initialiser les propriétés si la date n'existe pas encore
    if (!aggregatedData[date]) {
      aggregatedData[date] = {
        noSea: 0,
        seoAloneSeaAlone: 0,
        seoAloneAdsWithConcurent: 0,
        seoAloneSeoConcurentOnly: 0,
        total: 0, // Ajouter une propriété pour compter le total des cellules avec position === 1
      };
    }

    // Incrémenter le compteur total des cellules pour cette date
    aggregatedData[date].total += 1;

    if (cell.get_ads === false) {
      // Si get_ads est false, incrémenter noSea
      aggregatedData[date].noSea += 1;
    }

    if (cell.get_ads === true && cell.competitor.length === 1) {
      // Si get_ads est true et il y a un seul competitor
      const competitor = cell.competitor[0];
      const competitorDomainWithoutProtocol = competitor.url.replace(
        /^https?:\/\//i,
        ""
      );

      const domainWithoutProtocol = campaign.domain.replace(
        /^https?:\/\//i,
        ""
      );

      const domainsMatch =
        competitorDomainWithoutProtocol
          .toLowerCase()
          .includes(domainWithoutProtocol.toLowerCase()) ||
        domainWithoutProtocol
          .toLowerCase()
          .includes(competitorDomainWithoutProtocol.toLowerCase());

      if (domainsMatch) {
        aggregatedData[date].seoAloneSeaAlone += 1;
      }
    }

    if (cell.get_ads === true && cell.competitor.length > 1) {
      // Si get_ads est true et il y a plus d'un competitor
      const domainWithoutProtocol = campaign.domain.replace(
        /^https?:\/\//i,
        ""
      );

      const domainInCompetitors = cell.competitor.some((competitor) => {
        const competitorDomainWithoutProtocol = competitor.url.replace(
          /^https?:\/\//i,
          ""
        );
        return (
          competitorDomainWithoutProtocol
            .toLowerCase()
            .includes(domainWithoutProtocol.toLowerCase()) ||
          domainWithoutProtocol
            .toLowerCase()
            .includes(competitorDomainWithoutProtocol.toLowerCase())
        );
      });

      if (domainInCompetitors) {
        aggregatedData[date].seoAloneAdsWithConcurent += 1;
      }
    }

    if (cell.get_ads === true && cell.competitor.length > 0) {
      // Si get_ads est true et il y a au moins un competitor
      const domainWithoutProtocol = campaign.domain.replace(
        /^https?:\/\//i,
        ""
      );

      const noMatchingDomainInCompetitors = cell.competitor.every(
        (competitor) => {
          const competitorDomainWithoutProtocol = competitor.url.replace(
            /^https?:\/\//i,
            ""
          );
          return !(
            competitorDomainWithoutProtocol
              .toLowerCase()
              .includes(domainWithoutProtocol.toLowerCase()) ||
            domainWithoutProtocol
              .toLowerCase()
              .includes(competitorDomainWithoutProtocol.toLowerCase())
          );
        }
      );

      if (noMatchingDomainInCompetitors) {
        aggregatedData[date].seoAloneSeoConcurentOnly += 1;
      }
    }
  });

  // Étape 3: Calculer les pourcentages pour chaque date
  for (const date in aggregatedData) {
    const totalCells = aggregatedData[date].total;

    if (totalCells > 0) {
      aggregatedData[date].noSea = (
        (aggregatedData[date].noSea / totalCells) *
        100
      ).toFixed(2);

      aggregatedData[date].seoAloneSeaAlone = (
        (aggregatedData[date].seoAloneSeaAlone / totalCells) *
        100
      ).toFixed(2);

      aggregatedData[date].seoAloneAdsWithConcurent = (
        (aggregatedData[date].seoAloneAdsWithConcurent / totalCells) *
        100
      ).toFixed(2);

      aggregatedData[date].seoAloneSeoConcurentOnly = (
        (aggregatedData[date].seoAloneSeoConcurentOnly / totalCells) *
        100
      ).toFixed(2);
    }

    // Supprimer la propriété total car elle n'est plus nécessaire
    delete aggregatedData[date].total;
  }

  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  const aggregatedDataKeys = Object.keys(aggregatedData);

  let filteredForData;
  if (dateRange.length !== 0) {
    filteredForData = aggregatedDataKeys.filter((aggregatedDate) =>
      moment(aggregatedDate, "DD/MM/YYYY").isBetween(
        moment(dateRange[0]),
        moment(dateRange[1]),
        "day",
        "[]"
      )
    );
  } else {
    const last7Days = moment().subtract(7, "days");
    filteredForData = aggregatedDataKeys.filter(
      (aggregatedDate) =>
        moment(aggregatedDate, "DD/MM/YYYY").isSameOrAfter(last7Days, "day") &&
        moment(aggregatedDate, "DD/MM/YYYY").isSameOrBefore(moment(), "day")
    );
  }
  filteredForData.sort((a, b) =>
    moment(a, "DD/MM/YYYY").diff(moment(b, "DD/MM/YYYY"))
  );
  const dates = filteredForData;
  console.log("🚀 ~ SeaFocusChart ~ filteredForData:", filteredForData);

  const noSea = dates.map((date) => aggregatedData[date].noSea);
  const seoAloneSeaAlone = dates.map(
    (date) => aggregatedData[date].seoAloneSeaAlone
  );
  const seoAloneAdsWithConcurent = dates.map(
    (date) => aggregatedData[date].seoAloneAdsWithConcurent
  );
  const seoAloneSeoConcurentOnly = dates.map(
    (date) => aggregatedData[date].seoAloneSeoConcurentOnly
  );

  const data = {
    labels: dates,
    datasets: [
      {
        label: "Pas de SEA",
        data: noSea,
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "SEA annonceur uniquement",
        data: seoAloneSeaAlone,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "SEA concurrent + annonceurs",
        data: seoAloneAdsWithConcurent,
        backgroundColor: "rgba(255, 205, 86, 0.5)",
        borderColor: "rgba(255, 205, 86, 1)",
        borderWidth: 1,
      },
      {
        label: "SEA avec concurrents uniquement",
        data: seoAloneSeoConcurentOnly,
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Focus présence SEA (avec 1ère position SEO)",
        font: {
          size: 20,
          weight: "bold",
        },
      },
      legend: {
        position: "bottom",
        labels: {
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || "";
            const value = context.parsed.y + "%";
            return `${label}: ${value}`;
          },
        },
      },
      datalabels: {
        display: true,
        align: "center",
        anchor: "center",
        formatter: (value) => (value > 0 ? `${value}%` : ""),
        font: {
          weight: "bold",
          size: 12,
        },
        color: "black",
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
        grid: {
          display: true,
        },
        min: 0,
        max: 100,
      },
    },
    elements: {
      line: {
        tension: 0.4,
        backgroundColor: "#9BD0F5",
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
  };

  useEffect(() => {
    const chartNode = chartRef.current;

    if (chartNode) {
      const ctx = chartNode.getContext("2d");

      // Destroy previous chart instance if it exists
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      // Create new chart instance
      const newChartInstance = new Chart(ctx, {
        type: "bar",
        data,
        options,
      });

      chartInstanceRef.current = newChartInstance;
    }

    return () => {
      // Cleanup chart instance on component unmount
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [aggregatedData, dateRange]);

  return (
    <>
      <canvas
        ref={chartRef}
        id="seaFocusChart"
        style={{ backgroundColor: "#FFF" }}
      />
    </>
  );
};

export default SeaFocusChart;
