import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Loader,
  Text,
} from "@mantine/core";
import instance from "../axios/global";
import { AuthContext } from "../auth/auth";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import yngroup from "../assets/yuriandneilgroup.svg";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Espace vide détecté ! Veuillez remplir tous les champs.")
    .matches(
      "yuriandneil.com",
      "Vous devez utiliser votre adresse email yuriandneil.com"
    ),
  password: yup
    .string()
    .min(3, "Mot de passe incorrect ou manquant")
    .required("Espace vide détecté ! Veuillez remplir tous les champs."),
});

const Login = () => {
  console.log("yoo git");
  const navigate = useNavigate();
  const { setAuthState, authState } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await instance.post("/user/login", data);
      localStorage.setItem("accessToken", res.data.token);
      setLoading(false);
      setAuthState({ ...authState, status: true });
      navigate("/campagnes");
      setError(null);
      /*       toast.success("Embarquement réussi ! Prêt pour l'indexation", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      }); */
    } catch (error) {
      console.log("error :>> ", error);
      if (error.response && error.response.data && error.response.data.msg) {
        setError(error.response.data.msg);
      } else {
        setError("An error occurred during login.");
      }
      toast.error(
        "Houston, on dirait que votre mot de passe s'est égaré dans l'espace. Veuillez le retrouver ou nous contacter !",
        {
          position: toast.POSITION.TOP_LEFT,
        }
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("user");

    if (user) {
      navigate("/campagnes");
    }
  }, [authState.status, navigate]);
  return (
    <div className="container-login">
      <div className="left-container">
        <div className="titles">
          <h1>
            Synergy <span>Buddy</span>
          </h1>
          <h2>Bienvenue sur votre outil de tracking</h2>
        </div>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Email"
            placeholder="hello@gmail.com"
            size="md"
            {...register("email", {
              required: "Required",
            })}
            type="email"
            name="email"
          />
          <span className="warning-msg">
            {errors.email && errors.email.message}
          </span>
          <PasswordInput
            label="Mot de passe"
            placeholder="********"
            mt="md"
            size="md"
            name="password"
            {...register("password", {
              required: "Required",
            })}
          />
          <span className="warning-msg">
            {errors.password && errors.password.message}
          </span>
          <Checkbox label="Se souvenir de moi" mt="md" size="md" />

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <Loader color="#E2725B" size="lg" />
            </div>
          ) : (
            <Button fullWidth color="#E2725B" type="submit" mt="md" size="md">
              Connexion
            </Button>
          )}
        </form>
        <div className="container-ask-account">
          <div className="ask-account">
            <span>Vous n'avez pas encore de compte ? </span>
            <a href="mailto:lab@yuriandneil.com">Contactez-nous !</a>
          </div>
        </div>
      </div>
      <div className="right-container">
        <div className="yn-group-div">
          <img src={yngroup} alt="yuriandneil-group" />
        </div>
      </div>
    </div>
  );
};

export default Login;
