import React from "react";
import { MdTimer } from "react-icons/md";

const CalculateTime = ({ campaignLastCheck }) => {
  const calculateTimeDifference = () => {
    const lastCheckTime = new Date(campaignLastCheck);
    const currentTime = new Date();
    const timeDifferenceMs = currentTime - lastCheckTime;
    const timeDifferenceMinutes = Math.floor(timeDifferenceMs / (1000 * 60));

    const minutesInDay = 1440;
    const minutesInMonth = minutesInDay * 30;
    const minutesInYear = minutesInMonth * 12;

    if (timeDifferenceMinutes >= minutesInYear) {
      const years = Math.floor(timeDifferenceMinutes / minutesInYear);
      const remainingMinutes = timeDifferenceMinutes % minutesInYear;
      const months = Math.floor(remainingMinutes / minutesInMonth);
      const days = Math.floor(
        (remainingMinutes % minutesInMonth) / minutesInDay
      );
      return `${years} années ${months} mois ${days} jours`;
    } else if (timeDifferenceMinutes >= minutesInMonth) {
      const months = Math.floor(timeDifferenceMinutes / minutesInMonth);
      const remainingMinutes = timeDifferenceMinutes % minutesInMonth;
      const days = Math.floor(remainingMinutes / minutesInDay);
      const hours = Math.floor((remainingMinutes % minutesInDay) / 60);
      return `${months} mois ${days} jours ${hours} heures`;
    } else if (timeDifferenceMinutes >= minutesInDay) {
      const days = Math.floor(timeDifferenceMinutes / minutesInDay);
      const remainingMinutes = timeDifferenceMinutes % minutesInDay;
      const hours = Math.floor(remainingMinutes / 60);
      const minutes = remainingMinutes % 60;
      return `${days} jours ${hours} heures ${minutes} minutes`;
    } else if (timeDifferenceMinutes >= 60) {
      const hours = Math.floor(timeDifferenceMinutes / 60);
      const remainingMinutes = timeDifferenceMinutes % 60;
      return `${hours} heures ${remainingMinutes} minutes`;
    } else {
      return `${timeDifferenceMinutes} minutes`;
    }
  };
  return (
    <div className="container-stats">
      <MdTimer size={43} />
      <div className="img-p">
        <span>Dernier check</span>
        <p>{calculateTimeDifference() || 0}</p>
      </div>
    </div>
  );
};

export default CalculateTime;
