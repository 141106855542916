import React, { useContext, useEffect, useState } from "react";
import instance from "../axios/global";
import { Title, Select, Button } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import useProjectContext from "../hooks/useProjectContext";
import { AuthContext } from "../auth/auth";
import { Menu } from "@mantine/core";
import {
  IconDotsVertical,
  IconEye,
  IconReload,
  IconPower,
} from "@tabler/icons-react";
import { toast } from "react-toastify";

const ClientCampaign = () => {
  const [campaignList, setCampagaignList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { projectId, projectName } = useProjectContext();
  console.log("🚀 ~ ClientCampaign ~ projectName:", projectName);
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("all");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(1);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("all");
  const [clientList, setClientList] = useState([]);
  //const id = window.location.pathname.split("/")[2];
  const { id } = useParams();
  const navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);
  useEffect(() => {
    instance
      .get("/user/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          localStorage.setItem("user", JSON.stringify(res.data));
          setAuthState({
            name: res.data.firstName,
            email: res.data.email,
            id: res.data.id,
            status: true,
          });
        }
      });
  }, []);
  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const res = await instance.get(`/campaign/all/${id}`);
      setCampagaignList(res.data); // Correction de la faute de frappe
    } catch (error) {
      console.error(
        "🚀 ~ file: ClientCampaign.js:12 ~ fetchCampaigns ~ error:",
        error
      );
    } finally {
      setLoading(false); // Assure que setLoading(false) est appelé dans tous les cas
    }
  };

  useEffect(() => {
    if (id) {
      fetchCampaigns();
    } else {
      setCampagaignList([]);
      setLoading(false);
    }
  }, [id]);
  useEffect(() => {
    if (authState?.id) {
      const fetchClientList = async () => {
        try {
          const res = await instance.get(`/client/all/${authState.id}`);
          setClientList(res.data);
        } catch (error) {
          console.log(
            "🚀 ~ file: Homepage.js:38 ~ fetchClientList ~ error:",
            error
          );
        }
      };
      fetchClientList();
    }
  }, [authState?.id]);

  const handleDesactivateCampaign = async (id) => {
    try {
      const res = await instance.put(`/campaign/desactivate/${id}`);
      toast.success("Votre campagne a  été suspendue.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      fetchCampaigns();
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_LEFT,
      });
      console.log("🚀 ~ file: Report.js:205 ~ Report ~ error:", error);
    }
  };
  const handleActivateCampaign = async (id) => {
    try {
      const res = await instance.put(`/campaign/activate/${id}`);
      toast.success("Votre campagne a  été activé.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      fetchCampaigns();
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_LEFT,
      });
      console.log("🚀 ~ file: Report.js:205 ~ Report ~ error:", error);
    }
  };
  const filterCampaigns = () => {
    const filtered = campaignList.filter((campaign) => {
      const campaignKeyword = campaign.keyword_to_track.toLowerCase();

      // Filtre par mot-clé
      const keywordMatch = campaignKeyword.includes(searchTerm.toLowerCase());

      // Filtre par statut (isActive)
      const statusMatch =
        selectedStatus === "all" ||
        campaign.isActive === (selectedStatus === "actif");

      // Filtre par device
      const deviceMatch =
        selectedDevice === "all" || campaign.device === selectedDevice;

      return keywordMatch && statusMatch && deviceMatch;
    });

    setFilteredItems(filtered);
  };

  useEffect(() => {
    filterCampaigns(); // Call filterCampaigns when searchTerm changes
  }, [searchTerm, selectedStatus, campaignList, selectedDevice]);

  const optionsStatus = [
    { value: "all", label: "Tous les statuts" },
    { value: "actif", label: "Actif" },
    { value: "nonactif", label: "Non Actif" },
  ];
  const optionsDevices = [
    { value: "all", label: "Tous les devices" },
    { value: "desktop", label: "Desktop" },
    { value: "mobile", label: "Mobile" },
  ];

  const handleSort = (column) => {
    const newSortDirection = column === sortedColumn ? -sortDirection : 1;
    setSortDirection(newSortDirection);
    setSortedColumn(column);

    const sortedItems = [...filteredItems].sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (typeof valueA === "boolean" && typeof valueB === "boolean") {
        return newSortDirection * (valueA - valueB);
      } else if (column === "startDate" || column === "endDate") {
        // Convert string dates to moment objects
        const momentA = moment(valueA, "DD/MM/YYYY");
        const momentB = moment(valueB, "DD/MM/YYYY");

        return newSortDirection * (momentA - momentB);
      } else if (typeof valueA === "string" && typeof valueB === "string") {
        // Compare strings using localeCompare
        return newSortDirection * valueA.localeCompare(valueB);
      } else if (typeof valueA === "number" && typeof valueB === "number") {
        // Compare numbers
        return newSortDirection * (valueA - valueB);
      } else {
        // Handle other data types as needed
        return 0;
      }
    });

    setFilteredItems(sortedItems);
  };

  return (
    <div className="client-campaing">
      <div className="container">
        {loading ? (
          <>
            <p>Loading</p>
          </>
        ) : (
          <>
            {campaignList.length === 0 ? (
              <div style={{ textAlign: "center", marginTop: "50px" }}>
                Aucune campagne pour le moment
              </div>
            ) : (
              <>
                <div className="title-div">
                  <Title style={{ color: "#E2725B" }} order={2}>
                    Campagnes du client {projectName?.toUpperCase()}
                  </Title>

                  <div className="filter-bar">
                    <div className="search-select">
                      <input
                        className="search-bar"
                        type="text"
                        placeholder="Rechercher un mot-clé"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <Select
                        w={150}
                        style={{ cursor: "pointer", marginRight: " 12px" }}
                        placeholder="Filtrer par statuts"
                        color="gray.0"
                        id="statusFilter"
                        value={selectedStatus}
                        data={optionsStatus}
                        onChange={(newValue) => setSelectedStatus(newValue)}
                        checkIconPosition="right"
                      />
                      <Select
                        w={163}
                        style={{ cursor: "pointer", marginRight: " 12px" }}
                        placeholder="Filtrer par device"
                        color="gray.0"
                        id="deviceFilter"
                        value={selectedDevice}
                        data={optionsDevices} // Assurez-vous de définir optionsDevices dans votre code
                        onChange={(newValue) => setSelectedDevice(newValue)}
                        checkIconPosition="right"
                      />
                    </div>
                    <Button
                      onClick={() => navigate(`/campagne/${id}`)}
                      color="#E2725A"
                    >
                      CRÉER UNE CAMPAGNE
                    </Button>
                  </div>
                </div>
                <table style={{ minWidth: 1000 }} className="url-table">
                  <thead>
                    <tr>
                      <th className="th-url" onClick={() => handleSort("name")}>
                        Nom
                      </th>
                      <th onClick={() => handleSort("keyword_to_track")}>
                        Mot clé
                      </th>
                      <th onClick={() => handleSort("domain")}>Domaine</th>
                      <th>Date début</th>
                      <th>Date fin</th>
                      <th onClick={() => handleSort("location")}>Position</th>
                      <th onClick={() => handleSort("trigger_frequency")}>
                        Fréquence
                      </th>
                      <th onClick={() => handleSort("device")}>Device</th>
                      <th onClick={() => handleSort("isActive")}>Actif</th>
                      <th>Dernier check</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map((campaign, index) => {
                      const startDate = moment(campaign.start_date).format(
                        "DD/MM/YYYY"
                      );
                      const endDate = moment(campaign.end_date).format(
                        "DD/MM/YYYY"
                      );
                      const locationDecode = decodeURIComponent(
                        campaign.location
                      );
                      const goodLocation =
                        locationDecode === "null"
                          ? "NA"
                          : decodeURIComponent(locationDecode);
                      const lastCheck = moment(campaign.last_check).format(
                        "HH:mm DD/MM/YYYY"
                      );
                      return (
                        <tr key={index}>
                          <td className="td-url">{campaign.name}</td>
                          <td className="td-url">
                            {campaign.keyword_to_track}
                          </td>
                          <td className="td-url">{campaign.domain}</td>
                          <td className="td-url">{startDate}</td>
                          <td className="td-url">{endDate}</td>
                          <td className="td-url">{goodLocation}</td>
                          <td className="td-url">
                            {campaign?.trigger_frequency} min
                          </td>
                          <td className="td-url">{campaign?.device}</td>
                          <td
                            className={
                              campaign.isActive === true
                                ? "indexee"
                                : "nonindexee"
                            }
                          >
                            {campaign.isActive === true ? "Oui" : "Non"}
                          </td>
                          <td className="td-url">
                            {lastCheck !== "Invalid date"
                              ? lastCheck
                              : "En cours"}
                          </td>
                          <td>
                            <Menu
                              shadow="md"
                              width={200}
                              style={{ cursor: "pointer" }}
                            >
                              <Menu.Target>
                                <IconDotsVertical size={14} />
                              </Menu.Target>

                              <Menu.Dropdown>
                                <Menu.Label>Actions</Menu.Label>
                                <Menu.Item
                                  onClick={() =>
                                    lastCheck !== "Invalid date"
                                      ? navigate(`/rapport/${campaign.id}`)
                                      : null
                                  }
                                  leftSection={
                                    lastCheck !== "Invalid date" ? (
                                      <IconEye size={14} />
                                    ) : (
                                      <IconReload size={14} />
                                    )
                                  }
                                >
                                  {lastCheck !== "Invalid date"
                                    ? "Voir"
                                    : "En cours"}
                                </Menu.Item>
                                <Menu.Item
                                  leftSection={
                                    campaign.isActive ? (
                                      <IconPower size={14} color="red" />
                                    ) : (
                                      <IconPower size={14} color="green" />
                                    )
                                  }
                                  onClick={() =>
                                    campaign.isActive
                                      ? handleDesactivateCampaign(campaign.id)
                                      : handleActivateCampaign(campaign.id)
                                  }
                                >
                                  {campaign.isActive ? "Désactiver" : "Activer"}
                                </Menu.Item>
                              </Menu.Dropdown>
                            </Menu>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ClientCampaign;
