import React, { useState } from "react";
import {
  Title,
  Input,
  Select,
  Switch,
  Radio,
  CheckIcon,
  Container,
  Box,
  TextInput,
  Group,
  Button,
  Checkbox,
  NumberInput,
  Table,
  Text,
  Anchor,
  Pagination,
} from "@mantine/core";
import moment from "moment";
import { CSVLink } from "react-csv";

const Seolist = ({ cell }) => {
  const headers = [
    { label: "Date", key: "date" },
    { label: "Position", key: "position" },
    { label: "Url", key: "url" },
    { label: "Landing page", key: "landingPage" },
  ];
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const getAllCompetitors = () => {
    let allCompetitors = [];

    // Parcourez toutes les cellules
    cell.forEach((cell) => {
      const { competitorseo } = cell;
      if (competitorseo && competitorseo.length > 0) {
        // Si la cellule a des concurrents, ajoutez-les à la liste
        allCompetitors = [...allCompetitors, ...competitorseo];
      }
    });

    return allCompetitors;
  };
  const allCompetitors = getAllCompetitors();
  const sortedCompetitors = allCompetitors.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  const totalItems = sortedCompetitors.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedCompetitors = sortedCompetitors.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const rows = paginatedCompetitors.map((element) => {
    const competitorDate = moment(element.date).format("DD/MM/YYYY HH:mm");
    return (
      <Table.Tr
        key={element.id}
        //onClick={() => navigate(`/client/${element.id}/${element.name}`)}
        style={{ cursor: "pointer" }}
      >
        <Table.Td>{competitorDate}</Table.Td>
        {/*         <Table.Td>{element.url}</Table.Td> */}
        <Table.Td>{element.position}</Table.Td>
        <Table.Td>{element.url.substring(0, 40)}</Table.Td>
        <Table.Td>
          <Anchor href={element.url} target="_blank">
            {"Voir"}
          </Anchor>
        </Table.Td>
      </Table.Tr>
    );
  });
  return (
    <>
      {allCompetitors.length !== 0 ? (
        <>
          <div className="competitor-div">
            <Title style={{ color: "#E2725B" }} order={3}>
              Presence concurrent SEO
            </Title>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <CSVLink
                data={sortedCompetitors}
                headers={headers}
                filename={"positionseo.csv"}
              >
                <Button>Tout exporter en CSV</Button>
              </CSVLink>
            </div>
            <Table
              className="url-table"
              style={{ marginTop: "2rem" }}
              striped
              highlightOnHover
              withRowBorders={true}
            >
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Date</Table.Th>
                  {/*             <Table.Th>Url</Table.Th> */}
                  <Table.Th>Position</Table.Th>
                  <Table.Th>Url</Table.Th>
                  <Table.Th>Landing page </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Pagination
                total={totalPages}
                value={currentPage}
                onChange={handlePageChange}
                color="#E2725B"
                style={{ margin: "1rem" }}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Seolist;
