export const domain = [
  { value: "google.com.af", label: "google.com.af - Afghanistan" },
  { value: "google.al", label: "google.al - Albania" },
  { value: "google.dz", label: "google.dz - Algeria" },
  { value: "google.as", label: "google.as - American Samoa" },
  { value: "google.ad", label: "google.ad - Andorra" },
  { value: "google.ao", label: "google.ao - Angola" },
  { value: "google.ai", label: "google.ai - Anguilla" },
  { value: "google.com.ar", label: "google.com.ar - Argentina" },
  { value: "google.am", label: "google.am - Armenia" },
  { value: "google.com.au", label: "google.com.au - Australia" },
  { value: "google.at", label: "google.at - Austria" },
  { value: "google.az", label: "google.az - Azerbaijan" },
  { value: "google.bs", label: "google.bs - Bahamas" },
  { value: "google.bh", label: "google.bh - Bahrain" },
  { value: "google.com.bd", label: "google.com.bd - Bangladesh" },
  { value: "google.by", label: "google.by - Belarus" },
  { value: "google.be", label: "google.be - Belgium" },
  { value: "google.bz", label: "google.bz - Belize" },
  { value: "google.bj", label: "google.bj - Benin" },
  { value: "google.com.bo", label: "google.com.bo - Bolivia" },
  { value: "google.ba", label: "google.ba - Bosnia & Herzegovina" },
  { value: "google.com.br", label: "google.com.br - Brazil" },
  {
    value: "google.vg",
    label: "google.vg - British Virgin Islands",
  },
  { value: "google.com.bn", label: "google.com.bn - Brunei" },
  { value: "google.bg", label: "google.bg - Bulgaria" },
  { value: "google.bf", label: "google.bf - Burkina Faso" },
  { value: "google.bi", label: "google.bi - Burundi" },
  { value: "google.com.kh", label: "google.com.kh - Cambodia" },
  { value: "google.cm", label: "google.cm - Cameroon" },
  { value: "google.ca", label: "google.ca - Canada" },
  { value: "google.cv", label: "google.cv - Cape Verde" },
  {
    value: "google.cf",
    label: "google.cf - Central African Republic",
  },
  { value: "google.td", label: "google.td - Chad" },
  { value: "google.cl", label: "google.cl - Chile" },
  { value: "google.com.cn", label: "google.com.cn - China" },
  { value: "google.co", label: "google.co - Colombia" },
  { value: "google.cg", label: "google.cg - Congo" },
  {
    value: "google.cd",
    label: "google.cd - Democratic Republic of the Congo",
  },
  { value: "google.co.cr", label: "google.co.cr - Costa Rica" },
  { value: "google.ci", label: "google.ci - Côte d’Ivoire" },
  { value: "google.hr", label: "google.hr - Croatia" },
  { value: "google.com.cu", label: "google.com.cu - Cuba" },
  { value: "google.com.cy", label: "google.com.cy - Cyprus" },
  { value: "google.cz", label: "google.cz - Czechia" },
  { value: "google.dk", label: "google.dk - Denmark" },
  { value: "google.dj", label: "google.dj - Djibouti" },
  { value: "google.dm", label: "google.dm - Dominica" },
  {
    value: "google.com.do",
    label: "google.com.do - Dominican Republic",
  },
  { value: "google.com.ec", label: "google.com.ec - Ecuador" },
  { value: "google.com.eg", label: "google.com.eg - Egypt" },
  { value: "google.com.sv", label: "google.com.sv - El Salvador" },
  { value: "google.ee", label: "google.ee - Estonia" },
  { value: "google.com.et", label: "google.com.et - Ethiopia" },
  { value: "google.fi", label: "google.fi - Finland" },
  { value: "google.fr", label: "google.fr - France" },
  { value: "google.de", label: "google.de - Germany" },
  { value: "google.com.gh", label: "google.com.gh - Ghana" },
  { value: "google.com.hk", label: "google.com.hk - Hong Kong" },
  { value: "google.in", label: "google.in - India" },
  { value: "google.co.id", label: "google.co.id - Indonesia" },
  { value: "google.it", label: "google.it - Italy" },
  { value: "google.co.jp", label: "google.co.jp - Japan" },
  { value: "google.co.kr", label: "google.co.kr - South Korea" },
  { value: "google.com.mx", label: "google.com.mx - Mexico" },
  { value: "google.co.za", label: "google.co.za - South Africa" },
  { value: "google.co.uk", label: "google.co.uk - United Kingdom" },
  { value: "google.nu", label: "google.nu - Niue" },
  { value: "google.no", label: "google.no - Norway" },
  { value: "google.ne", label: "google.ne - Niger" },
  { value: "google.nz", label: "google.nz - New Zealand" },
  { value: "google.com.om", label: "google.com.om - Oman" },
  { value: "google.com.pk", label: "google.com.pk - Pakistan" },
  { value: "google.ps", label: "google.ps - Palestine" },
  { value: "google.com.pa", label: "google.com.pa - Panama" },
  {
    value: "google.com.pg",
    label: "google.com.pg - Papua New Guinea",
  },
  { value: "google.com.py", label: "google.com.py - Paraguay" },
  { value: "google.com.pe", label: "google.com.pe - Peru" },
  { value: "google.com.ph", label: "google.com.ph - Philippines" },
  { value: "google.pl", label: "google.pl - Poland" },
  { value: "google.pt", label: "google.pt - Portugal" },
  { value: "google.com.pr", label: "google.com.pr - Puerto Rico" },
  { value: "google.com.qa", label: "google.com.qa - Qatar" },
  { value: "google.ro", label: "google.ro - Romania" },
  { value: "google.ru", label: "google.ru - Russia" },
  { value: "google.rw", label: "google.rw - Rwanda" },
  { value: "google.sh", label: "google.sh - Saint Helena" },
  {
    value: "google.com.vc",
    label: "google.com.vc - Saint Vincent",
  },
  { value: "google.ws", label: "google.ws - Samoa" },
  { value: "google.sm", label: "google.sm - San Marino" },
  { value: "google.com.sa", label: "google.com.sa - Saudi Arabia" },
  { value: "google.sn", label: "google.sn - Senegal" },
  { value: "google.rs", label: "google.rs - Serbia" },
  { value: "google.sc", label: "google.sc - Seychelles" },
  { value: "google.com.sl", label: "google.com.sl - Sierra Leone" },
  { value: "google.com.sg", label: "google.com.sg - Singapore" },
  { value: "google.sk", label: "google.sk - Slovakia" },
  { value: "google.si", label: "google.si - Slovenia" },
  { value: "google.es", label: "google.es - Spain" },
  { value: "google.se", label: "google.se - Sweden" },
  { value: "google.ch", label: "google.ch - Switzerland" },
  { value: "google.tw", label: "google.tw - Taiwan" },
  { value: "google.com.tn", label: "google.com.tn - Tunisia" },
  { value: "google.co.th", label: "google.co.th - Thailand" },
  { value: "google.com.tr", label: "google.com.tr - Turkey" },
  {
    value: "google.co.tt",
    label: "google.co.tt - Trinidad & Tobago",
  },
  { value: "google.com.tw", label: "google.com.tw - Taiwan" },
  { value: "google.co.tz", label: "google.co.tz - Tanzania" },
  { value: "google.com.ua", label: "google.com.ua - Ukraine" },
  { value: "google.co.ug", label: "google.co.ug - Uganda" },
  { value: "google.co.uz", label: "google.co.uz - Uzbekistan" },
  { value: "google.com", label: "google.com - United States" },
  {
    value: "google.com.vi",
    label: "google.com.vi - United States Virgin Islands",
  },
  { value: "google.com.uy", label: "google.com.uy - Uruguay" },
  { value: "google.co.ve", label: "google.co.ve - Venezuela" },
  {
    value: "google.com.sb",
    label: "google.com.sb - Solomon Islands",
  },
  { value: "google.so", label: "google.so - Somalia" },
  { value: "google.lk", label: "google.lk - Sri Lanka" },
  { value: "google.sr", label: "google.sr - Suriname" },
  { value: "google.com.tj", label: "google.com.tj - Tajikistan" },
  { value: "google.tl", label: "google.tl - Timor-Leste" },
  { value: "google.tg", label: "google.tg - Togo" },
  { value: "google.tk", label: "google.tk - Tokelau" },
  { value: "google.to", label: "google.to - Tonga" },
  { value: "google.tt", label: "google.tt - Trinidad and Tobago" },
  { value: "google.tn", label: "google.tn - Tunisia" },
  { value: "google.tm", label: "google.tm - Turkmenistan" },
  { value: "google.ae", label: "google.ae - United Arab Emirates" },
  {
    value: "google.co.vi",
    label: "google.co.vi - US Virgin Islands",
  },
  { value: "google.vu", label: "google.vu - Vanuatu" },
  { value: "google.com.vn", label: "google.com.vn - Vietnam" },
  { value: "google.co.zm", label: "google.co.zm - Zambia" },
  { value: "google.co.zw", label: "google.co.zw - Zimbabwe" },
];
export const country = [
  { value: "afghanistan", label: "Afghanistan" },
  { value: "albania", label: "Albania" },
  { value: "algeria", label: "Algeria" },
  { value: "american samoa", label: "American Samoa" },
  { value: "andorra", label: "Andorra" },
  { value: "angola", label: "Angola" },
  { value: "anguilla", label: "Anguilla" },
  { value: "argentina", label: "Argentina" },
  { value: "armenia", label: "Armenia" },
  { value: "australia", label: "Australia" },
  { value: "austria", label: "Austria" },
  { value: "azerbaijan", label: "Azerbaijan" },
  { value: "bahamas", label: "Bahamas" },
  { value: "bahrain", label: "Bahrain" },
  { value: "bangladesh", label: "Bangladesh" },
  { value: "belarus", label: "Belarus" },
  { value: "belgium", label: "Belgium" },
  { value: "belize", label: "Belize" },
  { value: "benin", label: "Benin" },
  { value: "bolivia", label: "Bolivia" },
  { value: "bosnia & herzegovina", label: "Bosnia & Herzegovina" },
  { value: "brazil", label: "Brazil" },
  {
    value: "british virgin islands",
    label: "British Virgin Islands",
  },
  { value: "brunei", label: "Brunei" },
  { value: "bulgaria", label: "Bulgaria" },
  { value: "burkina faso", label: "Burkina Faso" },
  { value: "burundi", label: "Burundi" },
  { value: "cambodia", label: "Cambodia" },
  { value: "cameroon", label: "Cameroon" },
  { value: "canada", label: "Canada" },
  { value: "cape verde", label: "Cape Verde" },
  {
    value: "central african republic",
    label: "Central African Republic",
  },
  { value: "chad", label: "Chad" },
  { value: "chile", label: "Chile" },
  { value: "china", label: "China" },
  { value: "colombia", label: "Colombia" },
  { value: "congo", label: "Congo" },
  {
    value: "democratic republic of the congo",
    label: "Democratic Republic of the Congo",
  },
  { value: "costa rica", label: "Costa Rica" },
  { value: "côte d’ivoire", label: "Côte d’Ivoire" },
  { value: "croatia", label: "Croatia" },
  { value: "cuba", label: "Cuba" },
  { value: "cyprus", label: "Cyprus" },
  { value: "czechia", label: "Czechia" },
  { value: "denmark", label: "Denmark" },
  { value: "djibouti", label: "Djibouti" },
  { value: "dominica", label: "Dominica" },
  { value: "dominican republic", label: "Dominican Republic" },
  { value: "ecuador", label: "Ecuador" },
  { value: "egypt", label: "Egypt" },
  { value: "el salvador", label: "El Salvador" },
  { value: "estonia", label: "Estonia" },
  { value: "ethiopia", label: "Ethiopia" },
  { value: "finland", label: "Finland" },
  { value: "france", label: "France" },
  { value: "germany", label: "Germany" },
  { value: "ghana", label: "Ghana" },
  { value: "hong kong", label: "Hong Kong" },
  { value: "india", label: "India" },
  { value: "indonesia", label: "Indonesia" },
  { value: "italy", label: "Italy" },
  { value: "japan", label: "Japan" },
  { value: "south korea", label: "South Korea" },
  { value: "mexico", label: "Mexico" },
  { value: "south africa", label: "South Africa" },
  { value: "spain", label: "Spain" },
  { value: "united kingdom", label: "United Kingdom" },
  { value: "united states", label: "United States" },
  { value: "niue", label: "Niue" },
  { value: "norway", label: "Norway" },
  { value: "niger", label: "Niger" },
  { value: "new zealand", label: "New Zealand" },
  { value: "oman", label: "Oman" },
  { value: "pakistan", label: "Pakistan" },
  { value: "palestine", label: "Palestine" },
  { value: "panama", label: "Panama" },
  { value: "papua new guinea", label: "Papua New Guinea" },
  { value: "paraguay", label: "Paraguay" },
  { value: "peru", label: "Peru" },
  { value: "philippines", label: "Philippines" },
  { value: "poland", label: "Poland" },
  { value: "portugal", label: "Portugal" },
  { value: "puerto rico", label: "Puerto Rico" },
  { value: "qatar", label: "Qatar" },
  { value: "romania", label: "Romania" },
  { value: "russia", label: "Russia" },
  { value: "rwanda", label: "Rwanda" },
  { value: "saint helena", label: "Saint Helena" },
  { value: "saint vincent", label: "Saint Vincent" },
  { value: "samoa", label: "Samoa" },
  { value: "san marino", label: "San Marino" },
  { value: "saudi arabia", label: "Saudi Arabia" },
  { value: "senegal", label: "Senegal" },
  { value: "serbia", label: "Serbia" },
  { value: "seychelles", label: "Seychelles" },
  { value: "sierra leone", label: "Sierra Leone" },
  { value: "singapore", label: "Singapore" },
  { value: "slovakia", label: "Slovakia" },
  { value: "slovenia", label: "Slovenia" },
  { value: "sweden", label: "Sweden" },
  { value: "switzerland", label: "Switzerland" },
  { value: "tunisia", label: "Tunisia" },
  { value: "thailand", label: "Thailand" },
  { value: "turkey", label: "Turkey" },
  { value: "trinidad & tobago", label: "Trinidad & Tobago" },
  { value: "taiwan", label: "Taiwan" },
  { value: "tanzania", label: "Tanzania" },
  { value: "ukraine", label: "Ukraine" },
  { value: "uganda", label: "Uganda" },
  { value: "uzbekistan", label: "Uzbekistan" },
  {
    value: "united states virgin islands",
    label: "United States Virgin Islands",
  },
  { value: "uruguay", label: "Uruguay" },
  { value: "venezuela", label: "Venezuela" },
  { value: "solomon islands", label: "Solomon Islands" },
  { value: "somalia", label: "Somalia" },
  { value: "sri lanka", label: "Sri Lanka" },
  { value: "suriname", label: "Suriname" },
  { value: "tajikistan", label: "Tajikistan" },
  { value: "timor-leste", label: "Timor-Leste" },
  { value: "togo", label: "Togo" },
  { value: "tokelau", label: "Tokelau" },
  { value: "tonga", label: "Tonga" },
  { value: "trinidad and tobago", label: "Trinidad and Tobago" },
  { value: "turkmenistan", label: "Turkmenistan" },
  { value: "united arab emirates", label: "United Arab Emirates" },
  { value: "us virgin islands", label: "US Virgin Islands" },
  { value: "vanuatu", label: "Vanuatu" },
  { value: "vietnam", label: "Vietnam" },
  { value: "zambia", label: "Zambia" },
  { value: "zimbabwe", label: "Zimbabwe" },
];
export const langues = [
  { value: "af", label: "Afrikaans" },
  { value: "ak", label: "Akan" },
  { value: "sq", label: "Albanian" },
  { value: "am", label: "Amharic" },
  { value: "ar", label: "Arabic" },
  { value: "hy", label: "Armenian" },
  { value: "az", label: "Azerbaijani" },
  { value: "eu", label: "Basque" },
  { value: "be", label: "Belarusian" },
  { value: "bm", label: "Bemba" },
  { value: "bn", label: "Bengali" },
  { value: "bh", label: "Bihari" },
  { value: "bs", label: "Bosnian" },
  { value: "br", label: "Breton" },
  { value: "bg", label: "Bulgarian" },
  { value: "km", label: "Cambodian" },
  { value: "ca", label: "Catalan" },
  { value: "chr", label: "Cherokee" },
  { value: "ny", label: "Chichewa" },
  { value: "zh", label: "Chinese (Simplified)" },
  { value: "zh-TW", label: "Chinese (Traditional)" },
  { value: "co", label: "Corsican" },
  { value: "hr", label: "Croatian" },
  { value: "cs", label: "Czech" },
  { value: "da", label: "Danish" },
  { value: "nl", label: "Dutch" },
  { value: "en", label: "English" },
  { value: "eo", label: "Esperanto" },
  { value: "et", label: "Estonian" },
  { value: "ee", label: "Ewe" },
  { value: "fo", label: "Faroese" },
  { value: "fil", label: "Filipino" },
  { value: "fi", label: "Finnish" },
  { value: "fr", label: "French" },
  { value: "fy", label: "Frisian" },
  { value: "gl", label: "Galician" },
  { value: "ka", label: "Georgian" },
  { value: "de", label: "German" },
  { value: "el", label: "Greek" },
  { value: "gn", label: "Guarani" },
  { value: "gu", label: "Gujarati" },
  { value: "ht", label: "Haitian Creole" },
  { value: "ha", label: "Hausa" },
  { value: "haw", label: "Hawaiian" },
  { value: "he", label: "Hebrew" },
  { value: "hi", label: "Hindi" },
  { value: "hu", label: "Hungarian" },
  { value: "is", label: "Icelandic" },
  { value: "ig", label: "Igbo" },
  { value: "id", label: "Indonesian" },
  { value: "ia", label: "Interlingua" },
  { value: "ga", label: "Irish" },
  { value: "it", label: "Italian" },
  { value: "ja", label: "Japanese" },
  { value: "jv", label: "Javanese" },
  { value: "kn", label: "Kannada" },
  { value: "kk", label: "Kazakh" },
  { value: "rw", label: "Kinyarwanda" },
  { value: "rn", label: "Kirundi" },
  { value: "kg", label: "Kongo" },
  { value: "ko", label: "Korean" },
  { value: "kr", label: "Krio (Sierra Leone)" },
  { value: "ku", label: "Kurdish" },
  { value: "ckb", label: "Kurdish (Soranî)" },
  { value: "ky", label: "Kyrgyz" },
  { value: "lo", label: "Laothian" },
  { value: "la", label: "Latin" },
  { value: "lv", label: "Latvian" },
  { value: "ln", label: "Lingala" },
  { value: "lt", label: "Lithuanian" },
  // { value: "lo", label: "Lozi" },
  { value: "lg", label: "Luganda" },
  // { value: "lu", label: "Luo" },
  { value: "mk", label: "Macedonian" },
  { value: "mg", label: "Malagasy" },
  { value: "ms", label: "Malay" },
  { value: "ml", label: "Malayalam" },
  { value: "mt", label: "Maltese" },
  { value: "mi", label: "Maori" },
  { value: "mr", label: "Marathi" },
  { value: "mfe", label: "Mauritian Creole" },
  { value: "mo", label: "Moldavian" },
  { value: "mn", label: "Mongolian" },
  { value: "me", label: "Montenegrin" },
  { value: "ne", label: "Nepali" },
  { value: "pcm", label: "Nigerian Pidgin" },
  { value: "nso", label: "Northern Sotho" },
  { value: "no", label: "Norwegian" },
  { value: "nn", label: "Norwegian (Nynorsk)" },
  { value: "oc", label: "Occitan" },
  { value: "or", label: "Oriya" },
  { value: "om", label: "Oromo" },
  { value: "ps", label: "Pashto" },
  { value: "fa", label: "Persian" },
  { value: "pl", label: "Polish" },
  { value: "pt", label: "Portuguese" },
  { value: "pt-BR", label: "Portuguese (Brazil)" },
  { value: "pt-PT", label: "Portuguese (Portugal)" },
  { value: "pa", label: "Punjabi" },
  { value: "qu", label: "Quechua" },
  { value: "ro", label: "Romanian" },
  { value: "rm", label: "Romansh" },
  // { value: "rw", label: "Runyakitara" },
  { value: "ru", label: "Russian" },
  { value: "gd", label: "Scots Gaelic" },
  { value: "sr", label: "Serbian" },
  { value: "sh", label: "Serbo-Croatian" },
  { value: "st", label: "Sesotho" },
  { value: "tn", label: "Setswana" },
  { value: "sc", label: "Seychellois Creole" },
  { value: "sn", label: "Shona" },
  { value: "sd", label: "Sindhi" },
  { value: "si", label: "Sinhalese" },
  { value: "sk", label: "Slovak" },
  { value: "sl", label: "Slovenian" },
  { value: "so", label: "Somali" },
  { value: "es", label: "Spanish" },
  { value: "es-419", label: "Spanish (Latin American)" },
  { value: "su", label: "Sundanese" },
  { value: "sw", label: "Swahili" },
  { value: "sv", label: "Swedish" },
  { value: "tg", label: "Tajik" },
  { value: "ta", label: "Tamil" },
  { value: "tt", label: "Tatar" },
  { value: "te", label: "Telugu" },
  { value: "th", label: "Thai" },
  { value: "ti", label: "Tigrinya" },
  { value: "to", label: "Tonga" },
  { value: "lu", label: "Tshiluba" },
  { value: "tum", label: "Tumbuka" },
  { value: "tr", label: "Turkish" },
  { value: "tk", label: "Turkmen" },
  { value: "tw", label: "Twi" },
  { value: "ug", label: "Uighur" },
  { value: "uk", label: "Ukrainian" },
  { value: "ur", label: "Urdu" },
  { value: "uz", label: "Uzbek" },
  { value: "vi", label: "Vietnamese" },
  { value: "cy", label: "Welsh" },
  { value: "xh", label: "Xhosa" },
  { value: "yi", label: "Yiddish" },
  { value: "zu", label: "Zulu" },
];
