import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import { AuthContext } from "./auth/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@mantine/core/styles.css";
import Accueil from "./pages/Accueil";
import ProjectContextProvider from "./context/projectContext";

function App() {
  const [authState, setAuthState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    id: "",
    status: false,
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setAuthState({ ...user, status: true });
    }
  }, []);
  return (
    <BrowserRouter>
      <AuthContext.Provider value={{ authState, setAuthState }}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/*"
            element={
              <ProjectContextProvider>
                <Accueil />
              </ProjectContextProvider>
            }
          />
        </Routes>
        <ToastContainer />
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
