// SummaryChart.js
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";

const SummaryChart = ({ aggregatedData, dateRange }) => {
  console.log("🚀 ~ SummaryChart ~ aggregatedData:", aggregatedData);
  const chartRef = useRef(null);
  Chart.register(ChartDataLabels);

  useEffect(() => {
    const aggregatedDataKeys = Object.keys(aggregatedData);

    let filteredForData = aggregatedData;
    if (dateRange.length !== 0) {
      filteredForData = aggregatedDataKeys.filter((aggregatedDate) =>
        moment(aggregatedDate, "DD/MM/YYYY").isBetween(
          moment(dateRange[0]),
          moment(dateRange[1]),
          "day",
          "[]"
        )
      );
    } else {
      // Afficher les 7 derniers jours par défaut
      const last7Days = moment().subtract(7, "days");
      filteredForData = aggregatedDataKeys.filter(
        (aggregatedDate) =>
          moment(aggregatedDate, "DD/MM/YYYY").isSameOrAfter(
            last7Days,
            "day"
          ) &&
          moment(aggregatedDate, "DD/MM/YYYY").isSameOrBefore(moment(), "day")
      );
    }
    filteredForData.sort((a, b) =>
      moment(a, "DD/MM/YYYY").diff(moment(b, "DD/MM/YYYY"))
    );
    const dates = filteredForData;
    console.log("🚀 ~ useEffect ~ dates:", dates);

    const SEOPresenceWithoutAdsData = dates.map(
      (date) => aggregatedData[date].seoExclusivity
    );
    const adsPresenceData = dates.map(
      (date) => aggregatedData[date].adsVisibility
    );
    const adsAloneAndFirstInSEOData = dates.map(
      (date) => aggregatedData[date].serpConquest
    );
    const concurrentsAboveData = dates.map(
      (date) => aggregatedData[date].competitiveVisibility
    );

    const data = {
      labels: dates,
      datasets: [
        {
          label: "Exclusivité SEO",
          data: SEOPresenceWithoutAdsData,
          backgroundColor: (context) => {
            const gradient = context.chart.ctx.createLinearGradient(
              0,
              context.chart.height,
              0,
              0
            );
            gradient.addColorStop(0, "rgba(75, 192, 192, 0.5)");
            gradient.addColorStop(1, "rgba(75, 192, 192, 0.2)");
            return gradient;
          },
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
        {
          label: "Visibilité Ads",
          data: adsPresenceData,
          backgroundColor: (context) => {
            const gradient = context.chart.ctx.createLinearGradient(
              0,
              context.chart.height,
              0,
              0
            );
            gradient.addColorStop(0, "rgba(255, 99, 132, 0.5)");
            gradient.addColorStop(1, "rgba(255, 99, 132, 0.2)");
            return gradient;
          },
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
        {
          label: "Conquête SERP",
          data: adsAloneAndFirstInSEOData,
          backgroundColor: (context) => {
            const gradient = context.chart.ctx.createLinearGradient(
              0,
              context.chart.height,
              0,
              0
            );
            gradient.addColorStop(0, "rgba(255, 205, 86, 0.5)");
            gradient.addColorStop(1, "rgba(255, 205, 86, 0.2)");
            return gradient;
          },
          borderColor: "rgba(255, 205, 86, 1)",
          borderWidth: 1,
        },
        {
          label: "Visibilité Concurrentielle",
          data: concurrentsAboveData,
          backgroundColor: (context) => {
            const gradient = context.chart.ctx.createLinearGradient(
              0,
              context.chart.height,
              0,
              0
            );
            gradient.addColorStop(0, "rgba(54, 162, 235, 0.5)");
            gradient.addColorStop(1, "rgba(54, 162, 235, 0.2)");
            return gradient;
          },
          borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
        },
      ],
    };

    const options = {
      plugins: {
        title: {
          display: true,
          text: "Taux de présence vs concurrence par jour",
          font: {
            size: 20,
            weight: "bold",
          },
        },
        legend: {
          position: "bottom",
          labels: {
            font: {
              size: 14,
            },
          },
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.dataset.label || "";
              const value = context.parsed.y + "%";
              return `${label}: ${value}`;
            },
          },
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            callback: function (value) {
              return value + "%";
            },
          },
          grid: {
            display: true,
          },
          min: 0,
          max: 100,
        },
      },
      elements: {
        line: {
          tension: 0.4,
          backgroundColor: "#9BD0F5",
        },
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 20,
        },
      },
    };

    const ctx = chartRef.current.getContext("2d");

    const newChartInstance = new Chart(ctx, {
      type: "line",
      data,
      options,
    });

    // Nettoyage du graphique existant
    return () => {
      newChartInstance.destroy();
    };
  }, [aggregatedData, dateRange]);

  return <canvas ref={chartRef} style={{ backgroundColor: "#FFF" }} />;
};

export default SummaryChart;
