import React, { useEffect, useState } from "react";
import { Title, Tooltip, Select } from "@mantine/core";
import instance from "../axios/global";
import CompetitorList from "../components/CompetitorList";
import { DatePickerInput } from "@mantine/dates";
import "@mantine/dates/styles.css";
import { IconCalendar } from "@tabler/icons-react";
import { rem } from "@mantine/core";
import SummaryChart from "../components/SummaryChart";
import "react-datepicker/dist/react-datepicker.css";
import { TbSeo } from "react-icons/tb";
import { SiGoogleads } from "react-icons/si";
import { GiPlanetConquest } from "react-icons/gi";
import { MdVisibility } from "react-icons/md";
import { GiPositionMarker } from "react-icons/gi";
import Seolist from "../components/Seolist";
import "dayjs/locale/fr";
import moment from "moment";
import useProjectContext from "../hooks/useProjectContext";
import CalculateTime from "../components/CalculateTime";
import SeaFocusChart from "../components/SeaFocusChart";
import GscAdsChart from "../components/GscAdsChart";
import axios from "axios";
import EvolutionCtr from "../components/EvolutionCtr";
import EvolutionTrafic from "../components/EvolutionTrafic";

const Report = () => {
  const id = window.location.pathname.split("/")[2];
  const [reportData, setReportData] = useState({});
  const [aggregatedReportData, setAggredatedReportData] = useState({});
  const [campaignLastCheck, setCampaignLastCheck] = useState("");
  const [value, setValue] = useState([
    moment().subtract(7, "days").startOf("day").toDate(), // Date de début
    moment().toDate(), // Date de fin (aujourd'hui)
  ]);
  const [allCampaign, setAllCampaign] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [datesSelected, setDatesSelected] = useState(false);
  const { projectIdReport } = useProjectContext();
  const [gscAdsData, setGscAdsData] = useState([]);
  console.log("🚀 ~ Report ~ gscAdsData:", gscAdsData);

  const icon = (
    <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const res = await instance.get(`/campaign/all/${projectIdReport}`);
        setAllCampaign(res.data);
        if (!selectedCampaign && res?.data.length > 0) {
          setSelectedCampaign(id);
        }
      } catch (error) {
        console.log(
          "🚀 ~ file: ClientCampaign.js:12 ~ fetchCampaigns ~ error:",
          error
        );
      }
    };
    fetchCampaigns();
  }, [projectIdReport, selectedCampaign, id]);

  const fetchReport = async (startDate, endDate) => {
    try {
      const res = await instance.get(`/campaign/report/${selectedCampaign}`, {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });
      setReportData(res.data[0]);
      setCampaignLastCheck(res.data[0].last_check);
      setAggredatedReportData(res.data[1]);
    } catch (error) {
      console.log("🚀 ~ file: Report.js:43 ~ fetchReport ~ error:", error);
    }
  };
  useEffect(() => {
    if (selectedCampaign) {
      const startDate = moment(value[0]).format("YYYY-MM-DD");
      const endDate = moment(value[1]).format("YYYY-MM-DD");
      fetchReport(startDate, endDate);
    }
  }, [selectedCampaign]);

  const handleDateChange = (newValue) => {
    setValue(newValue);
    setDatesSelected(true);
    if (selectedCampaign) {
      const startDate = moment(newValue[0]).format("YYYY-MM-DD");
      const endDate = moment(newValue[1]).format("YYYY-MM-DD");
      fetchReport(startDate, endDate);
    }
  };

  const handleCampaignChange = (newCampaign) => {
    setSelectedCampaign(newCampaign);
    if (datesSelected) {
      const startDate = moment(value[0]).format("YYYY-MM-DD");
      const endDate = moment(value[1]).format("YYYY-MM-DD");
      fetchReport(startDate, endDate);
    }
  };

  const lastCheckTime = new Date(campaignLastCheck);

  // const handleProjectChange = (newProject) => {
  //   console.log("🚀 ~ handleProjectChange ~ newProject:", newProject);
  //   setSelectedCampaign(newProject);
  // };

  const aggregateData = (data) => {
    console.log("data :>> ", data);
    const aggregated = {
      seoExclusivity: 0,
      adsVisibility: 0,
      serpConquest: 0,
      competitiveVisibility: 0,
      averageSEOPosition: 0,
      count: 0,
    };

    Object.values(data)?.forEach((dayData) => {
      aggregated.seoExclusivity += dayData?.seoExclusivity;
      aggregated.adsVisibility += dayData?.adsVisibility;
      aggregated.serpConquest += dayData?.serpConquest;
      aggregated.competitiveVisibility += dayData?.competitiveVisibility;
      aggregated.averageSEOPosition += dayData?.averageSEOPosition;
      aggregated.count += 1;
    });

    // Calculate averages if count is greater than zero
    if (aggregated.count > 0) {
      aggregated.seoExclusivity /= aggregated.count;
      aggregated.adsVisibility /= aggregated.count;
      aggregated.serpConquest /= aggregated.count;
      aggregated.competitiveVisibility /= aggregated.count;
      aggregated.averageSEOPosition /= aggregated.count;
    }

    // Remove the count property as it is no longer needed
    delete aggregated.count;

    // Format numbers to two decimal places
    aggregated.seoExclusivity = aggregated.seoExclusivity.toFixed(2) || "0.00";
    aggregated.adsVisibility = aggregated.adsVisibility.toFixed(2) || "0.00";
    aggregated.serpConquest = aggregated.serpConquest.toFixed(2) || "0.00";
    aggregated.competitiveVisibility =
      aggregated.competitiveVisibility.toFixed(2) || "0.00";
    aggregated.averageSEOPosition =
      aggregated.averageSEOPosition.toFixed(2) || "0.00";

    return aggregated;
  };
  console.log(
    "process.env.REACT_APP_API_URL_GSC :>> ",
    process.env.REACT_APP_API_URL_GSC
  );
  useState(() => {
    const fetchGscAndAdsData = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL_GSC}/api/gsc/search-console-data`,
          {
            site_url: "https://mon-hotel-spa.com",
            start_date: "2024-06-01",
            end_date: "2024-06-08",
            queries: ["hotel"],
          }
        );
        console.log("res :>> ", res.data);
        setGscAdsData(res.data);
      } catch (error) {
        console.log("error :>> ", error);
      }
    };
    fetchGscAndAdsData();
  }, []);
  const aggregateDataReport = aggregateData(aggregatedReportData);
  console.log("🚀 ~ aggregateDataReport:", aggregateDataReport);
  return (
    <div className="report">
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          marginBottom: "2rem",
          width: "37rem",
        }}
      >
        <Select
          label="Sélectionnez une campage"
          placeholder="Sélectionnez une campage"
          data={allCampaign.map((project) => ({
            value: project.id,
            label: project.name,
          }))}
          value={selectedCampaign}
          onChange={(value) => handleCampaignChange(value)}
          checkIconPosition="right"
          w={290}
        />
        <div className="picker">
          <DatePickerInput
            type="range"
            label="Choisissez une plage de dates"
            placeholder="Choisissez une plage de dates"
            value={value}
            onChange={handleDateChange}
            leftSection={icon}
            locale="fr"
            leftSectionPointerEvents="none"
            clearable
            style={{ width: "18rem", position: "relative", right: "0" }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Title order={1} style={{ color: "#E2725B" }}>
          Rapport {reportData?.name}{" "}
        </Title>
        {/* <Button
          variant="light"
          color="pink"
          style={{ marginTop: "1rem" }}
          onClick={() => handleDesactivateCampaign()}
        >
          Desactiver campagne
        </Button> */}
      </div>
      <>
        <h2>Retrouvez toutes les statistiques d'une campagne</h2>
        <div className="all-stats">
          <CalculateTime campaignLastCheck={lastCheckTime} />
          <Tooltip
            color="#E2725B"
            multiline
            w={220}
            position="top"
            offset={{ mainAxis: 8, crossAxis: 9 }}
            label="Indique la fréquence à laquelle le site est en tête des résultats organiques sans la présence d'annonces concurentes."
          >
            <div className="container-stats">
              <TbSeo size={45} />
              <div className="img-p">
                <span>Exclusivité SEO</span>
                <p>
                  {aggregateDataReport?.seoExclusivity === "0.00"
                    ? "NC"
                    : `${aggregateDataReport?.seoExclusivity}%`}
                </p>
              </div>
            </div>
          </Tooltip>
          <Tooltip
            color="#E2725B"
            multiline
            w={220}
            position="top"
            offset={{ mainAxis: 8, crossAxis: 9 }}
            label="Indique la fréquence à laquelle des annonces sont affichées pour une requête spécifique."
          >
            <div className="container-stats">
              <SiGoogleads size={40} />
              <div className="img-p">
                <span>Visibilité Ads</span>
                <p>
                  {aggregateDataReport?.adsVisibility === "0.00"
                    ? "NC"
                    : `${aggregateDataReport?.adsVisibility}%`}
                </p>
              </div>
            </div>
          </Tooltip>
          <Tooltip
            color="#E2725B"
            multiline
            w={220}
            position="bottom"
            label="Indique la fréquence à laquelle le site apparaît seul en annonce payante et en première position dans les résultats organiques."
          >
            <div className="container-stats">
              <GiPlanetConquest size={45} />
              <div className="img-p">
                <span>Conquête SERP</span>
                <p>
                  {aggregateDataReport?.serpConquest === "0.00"
                    ? "NC"
                    : `${aggregateDataReport?.serpConquest}%`}
                </p>
              </div>
            </div>
          </Tooltip>
          <Tooltip
            color="#E2725B"
            multiline
            w={220}
            position="bottom"
            offset={{ mainAxis: 8, crossAxis: 1 }}
            label="Indique la fréquence à laquelle les concurrents apparaissent en annonces au-dessus du site client."
          >
            <div className="container-stats">
              <MdVisibility size={45} />
              <div className="img-p">
                <span>Visibilité Concurrentielle</span>
                <p>
                  {aggregateDataReport?.competitiveVisibility === "0.00"
                    ? "NC"
                    : `${aggregateDataReport?.competitiveVisibility}%`}
                </p>
              </div>
            </div>
          </Tooltip>
          <Tooltip
            color="#E2725B"
            multiline
            w={220}
            position="bottom"
            offset={{ mainAxis: 8, crossAxis: 1 }}
            label="Indique votre position moyenne en SEO sur le mot clé surveillé sur la période déterminée."
          >
            <div className="container-stats">
              <GiPositionMarker size={45} />
              <div className="img-p">
                <span>Position Moyenne SEO</span>
                <p>{aggregateDataReport?.averageSEOPosition || 0.0}</p>
              </div>
            </div>
          </Tooltip>
        </div>
      </>
      <div className="chart-picker">
        <SummaryChart
          aggregatedData={aggregatedReportData && aggregatedReportData}
          dateRange={value}
        />
      </div>
      <div className="chart-picker">
        <SeaFocusChart
          aggregatedData={reportData}
          dateRange={value}
          reportData={reportData}
        />
      </div>
      {/* <div className="chart-picker">
        <GscAdsChart
          aggregatedData={gscAdsData}
          dateRange={value}
          reportData={gscAdsData}
          text={"Évolution des clics SEO et SEA"}
        />
      </div> */}
      {/* <div className="chart-picker">
        <EvolutionCtr
          aggregatedData={reportData}
          dateRange={value}
          reportData={gscAdsData}
          text={"Évolution et tendance du CTR global"}
        />
      </div> */}
      {/* <div className="chart-picker">
        <EvolutionTrafic
          aggregatedData={reportData}
          dateRange={value}
          reportData={gscAdsData}
          text={"Évolution de la part de trafic capturé sur la demande"}
          type="bar"
        />
      </div> */}
      {reportData?.cell?.length > 0 && (
        <CompetitorList cell={reportData?.cell} />
      )}
      {reportData?.cell?.length > 0 && <Seolist cell={reportData?.cell} />}
    </div>
  );
};

export default Report;
