import React, { useEffect, useState, useContext, useMemo } from "react";
import instance from "../axios/global";
import { AuthContext } from "../auth/auth";
import Tables from "../components/Tables";
import { set } from "date-fns";
import { BsFilePersonFill } from "react-icons/bs";
import { MdCampaign } from "react-icons/md";
import { HiClipboardDocumentList } from "react-icons/hi2";
const Homepage = () => {
  const { authState, setAuthState } = useContext(AuthContext);
  const [clientList, setClientList] = useState([]);
  const [userReport, setUserReport] = useState([]);

  useEffect(() => {
    instance
      .get("/user/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          localStorage.setItem("user", JSON.stringify(res.data));
          setAuthState({
            name: res.data.firstName,
            email: res.data.email,
            id: res.data.id,
            status: true,
          });
        }
      });
  }, []);

  useEffect(() => {
    const fetchClientList = async () => {
      try {
        const res = await instance.get(`/client/all/${authState.id}`);
        console.log("res.data :>> ", res.data);
        console.log(
          "🚀 ~ file: Homepage.js:37 ~ fetchClientList ~ authState.id:",
          authState.id
        );
        setClientList(res.data);
      } catch (error) {
        console.log(
          "🚀 ~ file: Homepage.js:38 ~ fetchClientList ~ error:",
          error
        );
      }
    };
    fetchClientList();
  }, [authState.id]);

  useEffect(() => {
    const fetchUserReport = async () => {
      try {
        const res = await instance.get(`/user/report/${authState.id}`);
        console.log("res.data :>> ", res.data);
        setUserReport(res.data);
      } catch (error) {
        console.log(
          "🚀 ~ file: Homepage.js:38 ~ fetchUserReport ~ error:",
          error
        );
      }
    };
    fetchUserReport();
  }, [authState.id]);

  return (
    <div className="homepage">
      {/*    <Tables clientList={clientList} /> */}
      <>
        <h1 style={{ color: "#E2725B" }}>{"Bienvenue"}</h1>
        <h2>Retrouvez toutes les statistiques de votre compte</h2>
        <div className="all-stats">
          <div className="container-stats">
            <BsFilePersonFill size={43} />
            <div className="img-p">
              <span>Clients</span>
              <p>{userReport.numberOfClients || 0}</p>
            </div>
          </div>
          <div className="container-stats">
            <HiClipboardDocumentList size={45} />
            <div className="img-p">
              <span>Campagnes</span>
              <p>{userReport.numberOfCampaigns || 0}</p>
            </div>
          </div>
          <div className="container-stats">
            <MdCampaign size={45} />
            <div className="img-p">
              <span>Campagnes actives</span>
              <p>{userReport.numberOfActiveCampaigns || 0}</p>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Homepage;
